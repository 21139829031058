/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";

.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}
.was-validated .form-control:invalid, .was-validated .form-control:valid {background-image: none;}

.needs-validation .show-hide {
    right: 15px;
}
.required_error{
    font-size: 12px;
    color: red;
}

.banner_img {
  width: 100%;
  height: auto;
  display: block;
  max-width: 300px;
  max-height: 100px;
  object-fit: cover;
}

.action_btn {
    display: inline-block;
    font-weight: 400;
    color: #f8f8f8;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.2rem 0.3rem;
    font-size: 0.875rem;
    line-height: 1;
    border-radius: 0.25rem;
    cursor: pointer;
    margin: 0 8px 0 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
// .form_wrapper .form_btnDark, .dark_btn {
//   color: #fff;
//   background: #066CC1;
//   border: 1px solid #066CC1;
// }

// .form_wrapper .form_btnDark:hover, .dark_btn:hover {
//   color: #066CC1;
//   background-color: #fff;
// }

.fldbtn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.1rem 0.2rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.25rem;
  cursor: pointer;
  margin: 0 8px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.fldbtnEdit {
  box-shadow: 0 5px 10px 2px rgb(88 103 221 / 19%) !important;
  color: #045090 !important;
}

.fldbtnEdit:hover  {
  color: #fff !important;
  background-color: #045090 !important;
}

.fldbtnSuccess {
  box-shadow: 0 5px 10px 2px rgb(63 144 29 / 19%) !important;
  color: #3f901d !important;
}

.fldbtnSuccess:hover  {
  color: #fff !important;
  background-color: #3f901d !important;
}

.act_btn {
    display: inline-block;
    font-weight: 400;
    color: #f8f8f8;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.2rem;
    font-size: 0.8rem;
    line-height: 1.2;
    border-radius: 0.25rem;
    min-width: 80px;
    margin: 0 5px 0 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;  
}

.act_pill {
    border-radius: 20px;
}

.action_btn i {
    line-height: 1.2;
}

.custom-datatable input.search {
    margin: 0;
}

.resetPassModal .theme-form .form-group {
  position: relative;
}

// .table_wrapper {
//     width: 100%;
//     overflow-x: auto;
// }

.form-control:disabled, .form-control[readonly] {
  cursor: not-allowed;
}

.multiselect-dropdown .dropdown-btn {
  border: 1px solid #efefef !important;
  padding: 0.4rem 0.75rem !important;
  display: flex;
  flex-wrap: wrap;
}

.multiselect-dropdown .dropdown-btn:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
  top: 26px !important;
}

// .dropdown-list .item2 li.multiselect-item-checkbox:first-child {
//   border-bottom: 1px solid #cccccc;
//   padding-top: 20px;
//   padding-bottom: 20px;
//   background: lightgray;
// }


.userbg {
  border-radius: 50%;
  margin-top: -100px;
  transform: scale(1.5);
  height: 300px;
  width: 100%;
  background-color: #196cc1;
}

.drag_dropContainer {
  width: 100%;
  height: 250px;
  padding: 4rem;
  text-align: center;
  border: 2px dashed #7366ff;
  background: rgba(115, 102, 255, 0.1);
  border-radius: 6px;
  position: relative;
  margin: 0 auto 2rem;
}
  
.twoimg {
  display: flex;
  display: -ms-flexbox;
  align-items: flex-start;
  gap: 20px;
}

.drag_dropContainer input {
  opacity: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
  
.drag_dropContainer h3 label {
  display: block;
  font-weight: 500;
}
  
.drag_dropContainer h3 {
  font-size: 1rem;
  color: #2b2b2b;
  font-weight: 400;
  line-height: 1.2;
}
  
.drag_dropContainer i {
  display: block;
  font-size: 50px;
  color: #7366ff;
  line-height: 1;
}

.files-list {
  margin-bottom: 2rem;
}

.files-list .single-file {
  margin: 20px 0;
}

.files-list app-progress, .files-list app-catprogress, .files-list app-sliderprogress, .files-list app-attachedprogress {
  width: 98%;
}

.files-list .name {
  font-size: 16px;
  margin: 0 0 5px;
  font-weight: 400;
}

.files-list .size {
  font-size: 14px;
  color: #ABAFB3;
  margin: 0 0 5px;
}

.files-list .info {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}

.files-list .info .delete {
  width: 15px;
  cursor: pointer;
}

.tab_content {
  display: none;
}

.tab_content.on {
  display: block;
}

.uploadimgWrap {
  display: flex;
  display: -ms-flexbox;
  align-items: flex-start;
  margin: 10px 30px 20px 0;
}

.uploadimgWrap .doc_preview + .delete {
  margin-left: 10px;
}

.doc_preview {
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  gap: 10px 0;
  flex-direction: column;
  max-width: 150px;
}

.uploadimgWrap .doc_preview img {
  width: 140px;
}

.doc_wrapp {
  display: flex;
  display: -ms-flexbox;
  gap: 0 10px;
  justify-content: center;
}

// .doc_preview .delete {
//   position: relative;
//   top: -10px;
// }

.doc_preview + .delete i {
  cursor: pointer;
}


.updated_pdf img {
  height: 60px;
  margin: 0 auto;
  display: block;
  max-width: 120px;
}

.updated_pdf span {
  font-size: 12px;
}
.btn.bootstrap-touchspin-up, .btn.bootstrap-touchspin-down {
  padding: 0.48rem 0.75rem;
}

.min_maxWrap fieldset {
  width: 40%;
}

.dragdropform {
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  align-items: center;
  background-color: #f5f6f6 !important;
  border: 1px solid #ccc;
  border-top: none;
  padding-top: 10px !important;
  border-radius: 6px;
}

.dragdropform .formcomponent {
  flex: 0 0 49%;
  padding: 0 5px;
}

.dragdropform .fields-break {
    width: 100%;
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
}

.dragdropform .fields-break .field_Maintitle {
  border-bottom: 1px solid #ccc;
  margin: 0 0 20px;
  flex: 0 0 100%;
}

.dragdropform .field_Maintitle h4 {
  color: #666;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
}

.dragdropform .formcomponent .field {
  border: 1px solid #0000002e;
  clear: both;
  cursor: move;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #fff;
  margin-bottom: 12px;
}
.dragdropform .formcomponent .displayfield {
  border: 1px solid #0000002e;
  clear: both;
  padding: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #fff;
  margin-bottom: 12px;
  cursor: default;
}

.dragdropform .formcomponent .field h5 {
  margin: 0;
  color: #222;
  font-size: 13px;
  font-weight: 400;
}
.dragdropform .formcomponent .displayfield h5 {
  margin: 0;
  color: #222;
  font-size: 13px;
  font-weight: 400;
}

.dragdropform .formcomponent .field:hover, .dragdropform .formcomponent .field:focus, .dndDropArea .field:hover, .dndDropArea .field:focus {
  border: 1px dashed #066cc1;
}
.dragdropform .formcomponent .displayfield:hover, .dragdropform .formcomponent .displayfield:focus, .dndDropArea .displayfield:hover, .dndDropArea .displayfield:focus {
  border: 1px dashed #066cc1;
}

.dndDropArea .field.active:hover {
  border: 1px dashed #066cc1 !important;
}
.dndDropArea .displayfield.active:hover {
  border: 1px dashed #066cc1 !important;
}

.dragdropform .formcomponent .field .fa {
  line-height: 1.3;
  font-weight: 400;
}
.dragdropform .formcomponent .displayfield .fa {
  line-height: 1.3;
  font-weight: 400;
}

.dndDropArea .field {
  border: 1px solid transparent;
}
.dndDropArea .displayfield {
  border: 1px solid transparent;
}

.dndDropArea .field .row {
  margin-left: 0;
  margin-right: 0;
}
.dndDropArea .displayfield .row {
  margin-left: 0;
  margin-right: 0;
}


.parentCat_details ul {
  list-style: none;
}

.parentCat_details ul li {
  margin: 20px 0;
}

.parentCat_details ul li b {
  font-weight: 500;   
}

.bannerimgPreview img {
  max-width: 400px;
  width: 100%;
  height: 150px;
  object-fit: cover;
  margin-right: 30px;
}

input#alloweddomains, input#restricteddomains {
  outline: none;
  width: auto;
}

.tagsbox.form-control {
  // padding: 0.5em 0.75em;
  height: auto;
  overflow: hidden;
}

.searchWrap {
  width: 30%;
}

textarea.form-control {
  height: auto;
  min-height: 120px;
  resize: none;
}

select#perpage:hover {
  background-color: #fff;
}

.ngx-datatable span.datatable-header-cell-label {
  font-weight: 500;
}

.ngx-datatable .datatable-body .datatable-scroll {
  width: 100% !important;
}

.switch-sm .switch {
  margin-top: 0;
  margin-bottom: -3px;
}

.dragArea {
  padding: 10px;
  border: dashed #ddd 1px;
}

.dragArea:hover {
  border: dashed #066cc1 1px;
}


/** Ngx DataTable Scroller fix CSS **/

.ngx-datatable {
  // max-height: 550px !important;
  max-height: 1400px !important;
  overflow-y: auto;
}

.ngx-datatable .datatable-body {
  // max-height: 450px !important;
  max-height: 1310px !important;
}




/** Ngx DataTable Scroller fix CSS ends here **/

.list-persons .profile-mail {
  padding: 30px 0;
}

.list-persons .profile-mail .email-general ul {
  padding-right: 20px;
}

.list-persons .profile-mail .email-general ul li {
  color: #898989;
  padding-bottom: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}

.list-persons .profile-mail .email-general {
  padding-top: 50px;
}

.list-persons .profile-mail .media .media-body h5 {
  margin-bottom: 5px;
}

.email-wrap .media-body p {
  color: #2b2b2bb3;
  font-size: 12px;
}

.list-persons .profile-mail .media .media-body ul {
  margin-top: 5px;
}

.list-persons .profile-mail .media .media-body ul li {
  display: inline-block;
}

.list-persons .profile-mail .email-general ul li > span img {
  width: 80px;
  display: block;
}

.formFilters #nationality {
  min-width: 120px;
}

.formFilters input[type=number] {
  min-width: 150px;
  font-size: 14px;
}

.bookingDetails .activity-timeline {
  position: relative;
}

.bookingDetails .activity-timeline .media .activity-line {
  top: 20px;
  height: calc(100% - 40px);
  left: 6px;
}

.bookingDetails .activity-timeline .media + .media {
  margin-top: 25px;
}

.mouse-hover { 
  cursor: pointer; 
}

.multiselect-dropdown .dropdown-btn .selected-item-container {
  float: none !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  max-width: 250px !important;
  margin: 5px 0;
}


.formgroupbreadCrumb .page-title {
  padding: 20px 0 !important;
}
ngb-rating.starShape span {
  font-size: 20px;
  color: #2993d8;
}


.swal2-styled.swal2-cancel {
  background-color: rgb(221, 51, 51) !important;
}

.groupFieldLabel {
  position: absolute;
  top: 4px;
  background: #fff;
  z-index: 1;
  padding: 0 5px;
}

.groupFieldSettings {
  position: relative;
  top: 14px;
  z-index: 1;
  width: fit-content;
  left: -6px;
}

.field:hover .groupFieldSettings {
  background-color: #fff;
}
.displayfield:hover .groupFieldSettings {
  background-color: #fff;
}



/* Discount Group Accordion */

.discGroup .card:hover {
  border-color: #066cc159 !important;
}

.discGroup.accordion > .card > .card-header {
  padding: 0;
  border: 0;
}
.discGroup .ruleHeaderCont {
  background: #fff;
  height: 40px;
  font-weight: 500;
  line-height: 38px;
  padding-right: 15px;
  border: 1px solid #dbdbdb;
  border-bottom: 1px solid #d4d4d4;
  color: #333;
  font-size: 15px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 3px 3px #e8e8e8;
}

.discGroup .ruleHeaderCont.ruleOpen {
  background: #dbeaf7;
  border-bottom: 1px solid #63a3d9;
  color: #222;
  box-shadow: none;
}

.discGroup .ruleHeaderCont .flLeft.ruleArrow {
  font-weight: 500;
  color: #222;
  width: 40px;
  height: 40px;
  border-right: 1px solid #e7e7e7;
  margin-right: 15px;
  position: relative;
  text-align: center;
}

.discGroup .ruleHeaderCont .flLeft.ruleArrow .btn-link {
  color: var(--theme-deafult);
}

.discGroup .ruleHeaderCont.ruleOpen h6 {
  color: #000;
}

.discGroup .showhideBtns {
  display: none;
}

.discGroup .ruleHeaderCont:hover .showhideBtns, .discGroup .ruleHeaderCont.ruleOpen .showhideBtns {
  display: block;
}

.discGroup .card-body {
  padding: 0;
  border: 0;
}

.discGroup .ruleContainerWrapper.ruleConditionMainWrap {
  padding: 25px 25px 40px;
  border: 1px solid #BBE6DD;
  border-top: none;
}

.cursor-drag {
  cursor: all-scroll;
}

ul.domain-tags-list {
  display: flex;
  display: -ms-flexbox;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  flex-wrap: wrap;
}

ul.domain-tags-list li {
  max-width: calc(100% - 1px);
  display: inline-block;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin: 0.2em;
}

ul.domain-tags-list input#alloweddomains, ul.domain-tags-list input#allowedemails, ul.domain-tags-list input#restricteddomains {
  padding: 0;
  height: auto;
  padding-left: 0;
  width: auto;
  max-width: 100%;
  text-overflow: ellipsis;
  border: none;
}

.submissionTable tbody td {
  max-width: 200px;
}

.submissionTable tbody td.datetime {
  min-width: 220px;
  max-width: 220px;
}

.submissionTable tbody td.multiline, .submissionTable tbody td.singleLine {
  min-width: 200px;
  max-width: 200px;
  white-space: normal;
}

.submissionTable tbody td.decisionBox {
  min-width: 200px;
  max-width: 300px;
  white-space: normal;
}

.submissionTable tbody td.email {
  min-width: 250px;
  max-width: 250px;
}

.dragdropTable td.handle {
  position: relative;
  z-index: 0;
  cursor: grab;
}

.dragdropTable .gu-transit td.handle {
  cursor: grabbing;
}

.dragdropTable td.handle i {
	position: relative;
	z-index: -1;
	pointer-events: none;
}


button.customAddminus {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid transparent;
  border-radius: 50%;
  padding: 2px;
  background: #fff;
  line-height: 26px;
  text-align: center;
  margin: 0 5px;
  transition: all 0.2s ease;
}

button.customAddminus > i {
  font-weight: bold;
  font-size: 14px;
}

button.customAddminus.clone {
  border-color:#24A68A;
}

button.customAddminus.clone:hover {
  background-color:#24A68A;
}

button.customAddminus:hover > i {
  color: #fff !important;
}

button.customAddminus.clone > i {
  color: #24A68A;
}

button.customAddminus.delete {
  border-color: #e83232;
}

button.customAddminus.delete > i {
  color: #e83232;
}

button.customAddminus.delete:hover {
  background-color:#e83232;
}

.CustomizerDuplicateBox {
  padding: 20px 15px;
  &:nth-child(even) {
    background-color: #f7f7f7;
  }
}

.was-validated .ng-select-control.ng-invalid .ng-select-container {
  border: 1px solid#dc3545 !important;
}

.inviteForm ng-multiselect-dropdown .dropdown-list {
  position: relative;
}

.messageWrapper {
  text-align: center;
  padding: 100px 20px;
  background: transparent;
}

.messageWrapper p {
  font-size: 16px;
  font-weight: 400;
  color: #403f3f;
  margin-bottom: 10px;
}


/** Discount Group Pagination CSS **/

.disCountpagination ul.ngx-pagination {
  margin: 35px 0 0 auto;
  width: fit-content;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #dbeaf7;
}

.disCountpagination ul.ngx-pagination .disabled {
  color: #000;
  opacity: 0.5;
}

.disCountpagination ul.ngx-pagination .current {
  border-radius: 4px;
}

.disCountpagination ul.ngx-pagination li a {
  border-radius: 4px;
}

.customLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0 0 0 / 20%);
  z-index: 9999;
}

.customLoader .loaderWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.phoneno_select .ng-select .ng-select-container {
  border: none !important;
}

.phoneno_select .ng-select {
  padding: 0;
}


ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  max-height: 160px;
  overflow: hidden;
  overflow-y: auto;
}


input[type="password"]::-ms-reveal {
  display: none;
}

// .custom-datatable .ngx-datatable.bootstrap .datatable-header, .custom-datatable .ngx-datatable.bootstrap .datatable-body {
//   width: 100% !important;
// }

// .custom-datatable .ngx-datatable.bootstrap .datatable-body .datatable-body-row {
//   width: 100% !important;
// }

.bookingDoc .doc_preview {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 12px;
  box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 10%);
}

.bookingDoc .doc_preview img {
  border-radius: 10px 0 0;
  width: 120px;
  height: 100%;
  min-height: 70px;
  position: relative;
  transition: 0.3s ease all;
  -ms-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
}

.bookingDoc .doc_preview button.linkBtn {
  position: absolute;
  top: 100%;
  text-align: center;
  font-size: 12px;
  width: 120px;
  border-radius: 0 0 8px 8px;
  color: #066cc1;
  background-color: #fff;
  padding: 2px 0;
  border: none;
  margin: 0 !important;
  transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  z-index: 1;
}

.bookingDoc .doc_preview button.linkBtn:hover {
  background-color: #f4f4f4;
}

.bookingDoc .doc_preview:hover button {
  top: calc(100% - 22px);
}

.bookingDoc .doc_preview:hover img {
  transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
}

.bookingDoc .doc_preview:after {
  // content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  background: rgba(0 0 0/20%);
  z-index: 0;
}

.bookingDoc .doc_preview:hover:after {
  top: 0;
}

.bookingDoc .delete {
  position: absolute;
  top: -100%;
  right: 0;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  z-index: 1;
}

.bookingDoc .delete:hover {
  background:red;
}

.bookingDoc .doc_preview:hover .delete {
  top: 0;
}

.fieldOptInfo i {
  position: relative;
  transition: all 0.3s ease;
}

.fieldOptInfo i:hover {
  transform: translateY(-3px);
}




.cstlikert-radio [type="radio"] {
  position: static !important;
  margin-bottom: 10px;
}

.verticalLikert .cstlikert-radio input[type="radio"] {
  position: absolute !important;
  left: -15px !important;
  top: 3px;
}

.cstlikert-radio {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  flex-direction: column;
}

.likertBlock .cstlikertrow {
  width: 20%;
  position: relative;
}

.likertBlock .form-row {
  position: relative;
}

.likertBlock .form-row:before {
  content: '';
  position: absolute;
  background: #066cc1;
  width: calc(100% - 20%);
  height: 2px;
  left: 10%;
  top: 5px;
  z-index: 0;
}

.form-row.verticalLikert {
  flex-direction: column;
}

.verticalLikert .cstlikert-radio {display: inline-block;width: 100%;}

.verticalLikert .cstlikert-radio span {margin-left: 10px;}

.likertBlock .form-row.verticalLikert:before {content: '';height: calc(100% - 15%);width: 2px;left: -10px;}

.likertBlock .verticalLikert .cstlikertrow {
  margin-bottom: 30px;
  width: 100%;
}


.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell[ng-reflect-is-selected="true"] .datatable-body-cell-label a {
  color: #fff !Important;
}

.transactionDetails {
  overflow: hidden;
  overflow-x: auto;
}



// Responsive CSS starts here

@media screen and (max-width: 1280px) {
  .dragdropform .formcomponent {
    flex: 0 0 100%;
  }
}

@media screen and (max-width: 1199.98px) {
  .formFilters {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 991.98px) {

  .likertBlock .form-row {
      flex-direction: column !important;
  }

  .likertBlock .cstlikert-radio {
      display: inline-block;
      width: 100%;
      margin-left: 10px;
  }

  .cstlikert-radio input[type="radio"] {
      position: absolute !important;
      left: -15px !important;
      top: 3px;
  }

  .likertBlock .cstlikert-radio span {
      margin-left: 0;
  }

  .likertBlock .form-row:before {
      content: '';
      height: calc(100% - 50px);
      width: 2px;
      left: -10px;
  }

  .likertBlock .cstlikertrow {
      margin-bottom: 20px;
      width: 100%;
  }
}

@media screen and (max-width: 768px) {

  .datatable-body-cell-label .d-flex {
    flex-wrap: wrap;
    gap: 10px 0;
  }

  .searchWrap {
    width: 70%;
  }

}

@media screen and (max-width: 576px) {
  .min_maxWrap  {
    flex-wrap: wrap;
  }
  
  .min_maxWrap fieldset {
    width: 100%;
  }

  .ngx-datatable.bootstrap {
    font-size: 12px;
  }
}

@media screen and (max-width: 575.98px) {

  .page-wrapper.compact-wrapper .page-header .nav-right .nav-menus {
      margin-right: 0;
  }

  .dragdropform .formcomponent {
    flex: 0 0 48%;
  }

  .searchWrap {
    width: 100%;
  }

  .formFilters .form-group, .formFilters select.custom-select {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 475.98px) {
  .recaptchaResponsive, re-captcha {
    transform:scale(0.77);
    -webkit-transform:scale(0.77);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
  }
}


.reviewFile img {
  width: 150px;
  margin: 10px 10px 0 0;
}
.reviewFiledynamic img {
  width: 36px;
  margin: 10px 10px 0 0;
}
.btnDownload img {
  display: none;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.BUTTONCHANGE{
  display: flex;
    justify-content: flex-end;
}

.backbutton{
display: flex;
justify-content: end;
}
.Vertical-scroll{
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
.card-header-right.head_sec {
  display: flex !important;
  align-items: center;
}
.selector .ng-select.ng-select-single .ng-select-container {
  width: 250px;
}
.send-email{
  display: none;
}